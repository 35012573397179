<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 21C2 20.4477 2.44772 20 3 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5257 2.14935C13.8205 2.33156 14 2.65342 14 3V21C14 21.5523 13.5523 22 13 22C12.4477 22 12 21.5523 12 21V4.61803L6 7.61803V21C6 21.5523 5.55228 22 5 22C4.44772 22 4 21.5523 4 21V7C4 6.62123 4.214 6.27497 4.55279 6.10557L12.5528 2.10557C12.8628 1.95058 13.2309 1.96714 13.5257 2.14935Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1681 6.44541C12.4744 5.98588 13.0953 5.8617 13.5548 6.16806L19.5548 10.1681C19.833 10.3535 20.0001 10.6658 20.0001 11.0001V21.0001C20.0001 21.5524 19.5524 22.0001 19.0001 22.0001C18.4478 22.0001 18.0001 21.5524 18.0001 21.0001V11.5353L12.4454 7.83216C11.9859 7.5258 11.8617 6.90493 12.1681 6.44541Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 8C9.55228 8 10 8.44772 10 9V9.01C10 9.56229 9.55228 10.01 9 10.01C8.44772 10.01 8 9.56229 8 9.01V9C8 8.44772 8.44772 8 9 8Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 11C9.55228 11 10 11.4477 10 12V12.01C10 12.5623 9.55228 13.01 9 13.01C8.44772 13.01 8 12.5623 8 12.01V12C8 11.4477 8.44772 11 9 11Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 14C9.55228 14 10 14.4477 10 15V15.01C10 15.5623 9.55228 16.01 9 16.01C8.44772 16.01 8 15.5623 8 15.01V15C8 14.4477 8.44772 14 9 14Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 17C9.55228 17 10 17.4477 10 18V18.01C10 18.5623 9.55228 19.01 9 19.01C8.44772 19.01 8 18.5623 8 18.01V18C8 17.4477 8.44772 17 9 17Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconBuilding'
}
</script>